import React, { useEffect, useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import Select from "react-select"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { Button, Col, Form, Input, Label, Progress, Row } from "reactstrap"
import apis from "apis"
import AvField from "availity-reactstrap-validation/lib/AvField"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
//
const AddAvisAbsence = props => {
  const lng = localStorage.getItem("I18N_LANGUAGE")
  var userAuthEnseignant = JSON.parse(
    localStorage.getItem("userAuthEnseignant")
  )
  var enseignant_id = userAuthEnseignant.user.enseignant_id
  var nameEns = userAuthEnseignant.user.name
  //
  const [dateDebut, setDateDebut] = useState(new Date())
  const [dateFin, setDateFin] = useState(new Date())
  const [file, setFile] = useState(null)
  const [cause, setCause] = useState("")
  const [uploadPercent, setUploadPercent] = useState(0)
  //

  const addDateDebut = d => {
    setDateDebut(d)
  }

  const addDateFin = d => {
    setDateFin(d)
  }

  const addFile = e => {
    var file = e.target.files[0]
    setFile(file)
  }

  const save = async () => {
    var begin = dateDebut.getTime() / 1000
    var end = dateFin.getTime() / 1000
    if (end > begin) {
      if (cause == "") {
        toast.error("Cause obligatoire", {
          containerId: "A",
        })
      } else {
        // convert date début
        let date = dateDebut
        let month = "" + (date.getMonth() + 1)
        let day = "" + date.getDate()
        let year = date.getFullYear()
        if (month.length < 2) month = "0" + month
        if (day.length < 2) day = "0" + day
        let convertDateDebut = [year, month, day].join("-")
        // convert heure debut
        let hd = dateDebut
        let hoursHd = hd.getHours()
        let minutesHd = hd.getMinutes()
        minutesHd = minutesHd.toString()
        if (minutesHd.length < 2) {
          if (minutesHd == "0") {
            minutesHd = "00"
          } else {
            minutesHd.concat("", "0")
          }
        }
        let secondsHd = hd.getSeconds()
        secondsHd = secondsHd.toString()
        if (secondsHd.length < 2) {
          if (secondsHd == "0") {
            secondsHd = "00"
          } else {
            secondsHd.concat("", "0")
          }
        }
        let convertHd =
          convertDateDebut + " " + hoursHd + ":" + minutesHd + ":" + secondsHd
        // convert date fin
        let dateF = dateFin
        let monthF = "" + (dateF.getMonth() + 1)
        let dayF = "" + dateF.getDate()
        let yearF = dateF.getFullYear()
        if (monthF.length < 2) monthF = "0" + monthF
        if (dayF.length < 2) dayF = "0" + dayF
        let convertDateFin = [yearF, monthF, dayF].join("-")
        let hf = dateFin
        let hoursHf = hf.getHours()
        let minutesHf = hf.getMinutes()
        minutesHf = minutesHf.toString()
        if (minutesHf.length < 2) {
          if (minutesHf == "0") {
            minutesHf = "00"
          } else {
            minutesHf.concat("", "0")
          }
        }
        let secondsHf = hf.getSeconds()
        secondsHf = secondsHf.toString()
        if (secondsHf.length < 2) {
          if (secondsHf == "0") {
            secondsHf = "00"
          } else {
            secondsHf.concat("", "0")
          }
        }
        let convertHf =
          convertDateFin + " " + hoursHf + ":" + minutesHf + ":" + secondsHf
        //
        var pathFile = ""
        if (file != null) {
          pathFile = file.name
        }
        const res = await apis
          .post("avis/add", {
            date_debut: convertHd,
            date_fin: convertHf,
            cause: cause,
            enseignant_id: enseignant_id,
            pathFile: pathFile,
            etat: 0,
            avis_id: 1,
          })
          .then(res => {
            if (pathFile != "") {
              const formData = new FormData()
              formData.append("document", file)
              formData.append("annee", "2022")
              formData.append("entite", "Avis d'absence")
              formData.append("id", nameEns)
              const options = {
                onUploadProgress: progressEvent => {
                  const { loaded, total } = progressEvent
                  let percent = Math.floor((loaded * 100) / total)
                  console.log(`${loaded}kb of ${total}kb | ${percent}%`)
                  if (percent < 100) {
                    setUploadPercent(percent)
                  }
                },
              }
              const resA = apis
                .post("file/import", formData, options)
                .then(resA => {
                  setUploadPercent(100)
                  props.setSection(1)
                })
            }
          })
          .catch(() => {
            toast.error("Problème lors de l'insertion !", {
              containerId: "A",
            })
          })
      }
    } else {
      toast.error("Date fin doit étre supérieur a la date debut", {
        containerId: "A",
      })
    }
  }

  return (
    <React.Fragment>
      <Row>
        <AvForm>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Date début")}
                </Label>
                <DatePicker
                  selected={dateDebut}
                  className="form-control ddate"
                  dateFormat="dd/MM/yyyy h:mm aa"
                  onChange={e => setDateDebut(e)}
                  showTimeSelect
                  timeIntervals={15}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Date fin")}
                </Label>
                <DatePicker
                  selected={dateFin}
                  className="form-control ddate"
                  dateFormat="dd/MM/yyyy h:mm aa"
                  onChange={e => setDateFin(e)}
                  showTimeSelect
                  timeIntervals={15}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {" "}
                  {props.t("Cause")}
                </Label>
                <AvField
                  name="cause"
                  errorMessage="* Cause obligatoire"
                  required
                  className="form-control"
                  placeholder={props.t("Cause")}
                  type="text"
                  onChange={e => setCause(e.target.value)}
                />
              </div>
            </Col>
            <Col md="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Justification")}
                </Label>
                {uploadPercent <= 0 ? (
                  <Input
                    onChange={e => addFile(e)}
                    className="form-control"
                    type="file"
                    id="formFile"
                  />
                ) : null}
                {uploadPercent > 0 && uploadPercent < 100 ? (
                  <Progress
                    striped
                    className="progress-xl"
                    color="success"
                    value={uploadPercent}
                  >
                    {uploadPercent}%
                  </Progress>
                ) : null}
                {uploadPercent == 100 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Success
                    <i
                      style={{
                        paddingLeft: "5px",
                        color: "darkcyan",
                        paddingTop: "3.5px",
                      }}
                      className="fas fa-check me-1"
                    />
                  </div>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row></Row>
        </AvForm>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {" "}
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              className="text-center mt-4"
            >
              <Button
                type="button"
                color="warning"
                className="btn btn-warning  mb-2 me-2"
                onClick={props.back}
              >
                {props.t("Annuler")}
              </Button>
            </div>
          </Col>
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
              className="text-center mt-4"
            >
              <button type="button" className="btn btn-primary " onClick={save}>
                {props.t("Confirmer")}
              </button>
            </div>
          </Col>
        </div>
      </Row>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}
export default withRouter(withTranslation()(AddAvisAbsence))
AddAvisAbsence.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
