import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Row,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Label,
} from "reactstrap"
import { withRouter } from "react-router"
//i18n
import { withTranslation } from "react-i18next"
import API from "../../apis"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Select from "react-select"
import APIS from "../../apis"
import getDay from "date-fns/getDay"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"

const AddTest = props => {
  if (localStorage.getItem("userAuthEnseignant")) {
    var userAuthEnseignant = JSON.parse(
      localStorage.getItem("userAuthEnseignant")
    )
    var enseignant_id = userAuthEnseignant.user.enseignant_id
  }
  const [loading, setLoading] = useState(false)
  const [quarts, setQuarts] = useState([])
  const [jours, setJours] = useState([])
  const [loadingAdd, setLoadingAdd] = useState(true)
  const [modal, setModal] = useState(false)
  const [jourId, setJourId] = useState("")
  const [date, setDate] = useState(null)
  const [typeExamen, setTypeExamen] = useState([])
  const [selectTypeExamen, setSelectTypeExamen] = useState("")
  const [seanceId, setSeanceId] = useState("")
  var anneeScolaire = JSON.parse(localStorage.getItem("anneeScolaire"))
  var annee = anneeScolaire.Annee.id
  useEffect(async () => {
    if(props.type == 1 ){
      const resT = await APIS.get("type_examen/select_ds").then(resT => {
        setTypeExamen(resT.data.Type_examen)
      })
    }else{
      const resT = await APIS.get("type_examen/select_test").then(resT => {
        setTypeExamen(resT.data.Type_examen)
      })
    }

    const resE = await API.post("emploi/get_by_id", {
      enseignant_id: enseignant_id,
      semestre_id: props.semestre_value,
      annee_id:annee
    }).then(resE => {
      setQuarts(resE.data.emploi[0].quarts)
      setJours(resE.data.emploi[0].jours)
      setLoading(true)
    })
  }, [props.semestre_value])

  const isWeekday = date => {
    const day = getDay(date)
    return day == jourId
  }

  const toggle = (element, elem) => {
    setModal(!modal)
    setLoadingAdd(true)
    setDate(null)
    setSelectTypeExamen("")
    var jour_id = elem
    var seance_id = element.id_seance
    setJourId(jour_id)
    setSeanceId(seance_id)
  }

  const save = async () => {
    if (date == null) {
      toast.error("⛔ Date obligatoire !", {
        containerId: "A",
      })
    } else if (selectTypeExamen != "") {
      setLoadingAdd(false)
      // convert date début
      let dateExamen = date
      let month = "" + (dateExamen.getMonth() + 1)
      let day = "" + dateExamen.getDate()
      let year = dateExamen.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      let convertDate = [year, month, day].join("-")
      //
      const res = await APIS.post("avis/examen/adds", {
        date: convertDate,
        type_examen_id: selectTypeExamen.value,
        seance_id: seanceId,
      })
        .then(res => {
          setModal(false)
        })
        .catch(() => {
          toast.error("Problème lors de l'insertion !", {
            containerId: "A",
          })
        })
    } else {
      toast.error("⛔ Type examen obligatoire !", {
        containerId: "A",
      })
    }
  }

  return (
    <React.Fragment>
      {loading ? (
        <Row>
          <div>
            <div className="table-responsive mt-4">
              <table className="table table-bordered text-center">
                <thead className="table table-light">
                  <tr>
                    <th></th>
                    {quarts.map(el => (
                      <th key={el.id_quart} style={{ whiteSpace: "nowrap" }}>
                        {el.label_quart}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {jours.map((elem, index) => (
                    <tr key={index}>
                      <td style={{ fontWeight: "bold" }}>{elem.label_jour}</td>
                      {elem.seances.map((element, indice) => (
                        <td
                          key={indice}
                          style={{ padding: "0px" }}
                          className="td-emploi"
                        >
                          {element.options.map((elle, ind) => (
                            <div
                              key={ind}
                              style={{
                                padding: "5px 5px 5px 5px",
                                cursor: "pointer",
                              }}
                              onClick={() => toggle(elle, elem.id_jour)}
                            >
                              <p
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {elle.label_matiere}
                              </p>
                              <p
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {elle.label_group}
                              </p>
                              <p
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {elle.label_salle}
                              </p>
                              {element.type == 1 ? (
                                <div className="div-icon">
                                  <span className="icon-cour">C</span>
                                  {element.quan == 1 ? (
                                    <div className="icon_quan"></div>
                                  ) : null}
                                </div>
                              ) : element.type == 2 ? (
                                <div className="div-icon">
                                  <span className="icon-td">TD</span>
                                  {element.quan == 1 ? (
                                    <div className="icon_quan"></div>
                                  ) : null}
                                </div>
                              ) : element.type == 3 ? (
                                <div className="div-icon">
                                  <span className="icon-tp">TP</span>
                                  {element.quan == 1 ? (
                                    <div className="icon_quan"></div>
                                  ) : null}
                                </div>
                              ) : element.type == 4 ? (
                                <div className="div-icon">
                                  <span className="icon-autre">A</span>
                                  {element.quan == 1 ? (
                                    <div className="icon_quan"></div>
                                  ) : null}
                                </div>
                              ):(null)}
                            </div>
                          ))}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Row>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Spinner type="grow" className="ms-6" color="primary" />
          </div>
          <h4 style={{ textAlign: "center", marginTop: "2%" }} className="ms-6">
            Chargement ...
          </h4>
        </div>
      )}
      <Modal
        style={{
          maxWidth: "700px",
          width: "100%",
        }}
        isOpen={modal}
        toggle={toggle}
        centered={true}
      >
        <ModalHeader
          style={{
            width: "100% !important",
            textAlign: "center !important",
          }}
          toggle={toggle}
          tag="h4"
        >
          Ajouter DS
        </ModalHeader>
        <ModalBody>
          {loadingAdd ? (
            <Fragment>
              <Row>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">
                      {props.t("Date")}
                      <span style={{ color: "red", paddingLeft: "5px" }}>
                        *
                      </span>
                    </Label>
                    <DatePicker
                      selected={date}
                      filterDate={isWeekday}
                      className="form-control ddate"
                      dateFormat="dd/MM/yyyy"
                      onChange={e => setDate(e)}
                    />
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Type examen</Label>
                    <Select
                      options={typeExamen}
                      isSearchable={true}
                      value={selectTypeExamen}
                      onChange={e => setSelectTypeExamen(e)}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {" "}
                  <Col lg="6">
                    <div className="text-center mt-4">
                      <button
                        type="button"
                        className="btn btn-primary "
                        onClick={save}
                      >
                        CONFIRMER
                      </button>
                    </div>
                  </Col>
                </div>
              </Row>
            </Fragment>
          ) : (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {" "}
                <Spinner type="grow" className="ms-6" color="primary" />
              </div>
              <h4
                style={{ textAlign: "center", marginTop: "2%" }}
                className="ms-6"
              >
                {" "}
                {"Chargement"} ...{" "}
              </h4>
            </div>
          )}
        </ModalBody>
      </Modal>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(AddTest))
AddTest.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  semestre_value: PropTypes.object,
  type:PropTypes.any
}
