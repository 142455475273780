import PropTypes from "prop-types"
// import React from "react"
import {Switch, BrowserRouter as Router} from "react-router-dom"
import {connect} from "react-redux"
// Import Routes all
import {authProtectedRoutes, publicRoutes} from "./routes"
// Import all middleware
import Authmiddleware from "./routes/route"
// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
// Import scss
import "./assets/scss/theme.scss"
import fakeBackend from "./helpers/AuthType/fakeBackend"
import {useIdleTimer} from "react-idle-timer"
import React, {useEffect, useState} from "react"

// Activating fake backend
fakeBackend()

const App = props => {
    var lng = localStorage.getItem("I18N_LANGUAGE")
    const handleOnIdle = event => {
        if (event === undefined) {
            localStorage.clear()
        }
    }
    // useEffect(() => {
    //   const handleContextMenu = (e) => {
    //     e.preventDefault();
    //   };    document.addEventListener('contextmenu', handleContextMenu);    return () => {
    //     document.removeEventListener('contextmenu', handleContextMenu);
    //   };
    // }, []);

    useEffect(() => {
        // function areDevToolsOpened() {
        //     const devtools = /./;
        //     devtools.toString = function() {
        //         // Afficher une alerte ou effectuer une action ici
        //         alert('Attention ! Les outils de développement sont ouverts.');        // Empêcher le chargement du site en lançant une exception
        //         throw new Error('Le chargement du site a été interrompu car les outils de développement sont ouverts.');
        //     };      console.log('%c', devtools);
        // }    // Appeler la fonction pour vérifier si les outils de développement sont ouverts
        // areDevToolsOpened();
        // while(areDevToolsOpened()){
        //     areDevToolsOpened();
        // }

//         function areDevToolsOpened() {
//             let toolsOpened = true;
//
//             const devtools = /./;
//             devtools.toString = function() {
//                 // Afficher une alerte ou effectuer une action ici
//                 alert('Attention ! Les outils de développement sont ouverts.');
//                 // Empêcher le chargement du site en lançant une exception
//                 throw new Error('Le chargement du site a été interrompu car les outils de développement sont ouverts.');
//             };
//
//             console.log('%c', devtools);
//
//             return toolsOpened;
//         }
//
// // Appeler la fonction pour vérifier si les outils de développement sont ouverts
//         do {
//             areDevToolsOpened();
//         } while (areDevToolsOpened());



//         function areDevToolsOpened() {
//             const devtools = /./;
//             devtools.toString = function() {
// // Check if the development tools are opened
//                 if (devtools.test(window.location.href)) {
// // If the development tools are opened, do not display an alert
//                     return;
//                 }
// // Otherwise, display an alert
//                 alert('Attention ! Les outils de développement sont ouverts.');
// // Prevent the page from loading by throwing an exception
//                 throw new Error('Le chargement du site a été interrompu car les outils de développement sont ouverts.');
//             };
//             console.log('%c', devtools);
//         }
// // Call the function to check if the development tools are opened
//         areDevToolsOpened();
// // Loop while the development tools are opened
//         while (areDevToolsOpened()) {
//             areDevToolsOpened();
//         }

        const handleContextMenu = (e) => {
            e.preventDefault();
        };
        window.addEventListener('contextmenu', function(e) {
            e.preventDefault();
        });
        document.addEventListener('contextmenu', handleContextMenu);
        const handleKeyDown = (event) => {
            if (event.key === 'F12') {
                event.preventDefault();
                // alert('L\'inspection du navigateur n\'est pas autorisée.');
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);


    const handleOnActive = event => {
    }
    const handleOnAction = event => {
    }
    const {getRemainingTime, getLastActiveTime} = useIdleTimer({
        timeout: 4 * 60 * 60 * 1000,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500,
    })

    function getLayout() {
        let layoutCls = VerticalLayout
        switch (props.layout.layoutType) {
            case "horizontal":
                layoutCls = HorizontalLayout
                break
            default:
                layoutCls = VerticalLayout
                break
        }
        return layoutCls
    }

    const Layout = getLayout()
    return (
        <div>
            <React.Fragment>
                <Router>
                    <Switch>
                        {publicRoutes.map((route, idx) => (
                            <Authmiddleware
                                path={route.path}
                                layout={NonAuthLayout}
                                component={route.component}
                                key={idx}
                                isAuthProtected={false}
                                exact
                            />
                        ))}

                        {lng == "ar"
                            ? authProtectedRoutes.map((route, idx) => (
                                <Authmiddleware
                                    path={route.path}
                                    layout={Layout}
                                    component={route.component}
                                    key={idx}
                                    isAuthProtected={true}
                                    exact
                                />
                            ))
                            : authProtectedRoutes.map((route, idx) => (
                                <Authmiddleware
                                    path={route.path}
                                    layout={Layout}
                                    component={route.component}
                                    key={idx}
                                    isAuthProtected={true}
                                    exact
                                />
                            ))}
                    </Switch>
                </Router>
            </React.Fragment>
        </div>
    )
}

App.propTypes = {
    layout: PropTypes.any,
}

const mapStateToProps = state => {
    return {
        layout: state.Layout,
    }
}

export default connect(mapStateToProps, null)(App)
