import React, { useState } from "react"
import PropTypes from "prop-types"
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import { withTranslation } from "react-i18next"
import classnames from "classnames"
import PremiereAnneeSemestreUn from "./PremiereAnneeSemestreUn"
import DeuxiemeAnneeSemestreUn from "./DeuxiemeAnneeSemestreUn"
import TroisiemeAnneeSemestreUn from "./TroisiemeAnneeSemestreUn"

const DataTableValidationSemestre1 = props => {
  const [activeTab, setactiveTab] = useState("1")

  return (
    <React.Fragment>
      <Nav className="icon-tab nav-justified">
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab === "1",
            })}
            onClick={() => {
              setactiveTab("1")
            }}
          >
            <span className="d-none d-sm-block">1 ére année</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab === "2",
            })}
            onClick={() => {
              setactiveTab("2")
            }}
          >
            <span className="d-none d-sm-block">2 éme année</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: activeTab === "3",
            })}
            onClick={() => {
              setactiveTab("3")
            }}
          >
            <span className="d-none d-sm-block">3 éme année</span>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab} className="p-3 text-muted">
        <TabPane tabId="1">
          <PremiereAnneeSemestreUn />
        </TabPane>
        <TabPane tabId="2">
          <DeuxiemeAnneeSemestreUn />
        </TabPane>
        <TabPane tabId="3">
          <TroisiemeAnneeSemestreUn />
        </TabPane>
      </TabContent>
    </React.Fragment>
  )
}

export default withTranslation()(DataTableValidationSemestre1)
DataTableValidationSemestre1.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
