import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  Form,
  Label,
  Row,
  Input,
  Card,
  CardBody,
  Container,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import APIS from "../../apis"
import { withTranslation } from "react-i18next"
import Select from "react-select"

const AddFicheLibre = props => {
  var userAuthEnseignant = JSON.parse(
    localStorage.getItem("userAuthEnseignant")
  )
  var enseignant_id = userAuthEnseignant.user.enseignant_id
  const [semestre, setSemestre] = useState([
    { value: 1, label: "Semestre 1" },
    { value: 2, label: "Semestre 2" },
  ])
  const [selectSemestre, setSelectSemestre] = useState("")
  const [jours, setJours] = useState([
    { value: 1, label: "Lundi" },
    { value: 2, label: "Mardi" },
    { value: 3, label: "Mercredi" },
    { value: 4, label: "Jeudi" },
    { value: 5, label: "Vendredi" },
    { value: 6, label: "Samedi" },
  ])
  const [selectJour, setSelectJour] = useState("")
  const [matiere, setMatiere] = useState([])
  const [selectMatiere, setSelectMatiere] = useState("")
  const [type, setType] = useState([])
  const [selectType, setSelectType] = useState("")
  const [enseignant, setEnseignant] = useState([])
  const [selectEnseignant, setSelectEnseignant] = useState("")
  const [nbrGroupe, setNbrGroupe] = useState("")

  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }
  const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  }

  const formatGroupLabel = data => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  )

  useEffect(async () => {
    const resE = await APIS.get("enseignant/select_by_departement").then(
      resE => {
        setEnseignant(resE.data.Enseignant)
      }
    )
  }, [])

  const getType = event => {
    setSelectMatiere(event)
    const res = APIS.post("matiere/get_detail", {
      matiere_id: event.value,
    }).then(res => {
      setType(res.data.Matiere)
    })
  }

  const getMatiere = async event => {
    setSelectSemestre(event)
    const res = await APIS.post(
      "matiere/get_matiere_by_departement_by_enseignant",
      { enseignant_id: enseignant_id, semestre_id: event.value }
    ).then(res => {
      setMatiere(res.data.Matiere)
    })
  }

  const save = async () => {
    const res = await APIS.post("fiche/add_libre", {
      semestre_id: selectSemestre.value,
      jour_id: selectJour,
      matiere_id: selectMatiere.value,
      enseignant_id: selectEnseignant.value,
      nbr_groupe: nbrGroupe,
      type_matiere: selectType.value,
    }).then(res => {
      props.history.push("/FicheVoeux")
    })
  }

  return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Card>
              <CardBody>
                <Form>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          {props.t("Semestre")} :
                        </Label>
                        <Select
                            options={semestre}
                            isSearchable={true}
                            onChange={e => getMatiere(e)}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          {props.t("Choix des jours")} :
                        </Label>
                        <Select
                            isMulti={true}
                            options={jours}
                            isSearchable={true}
                            onChange={e => setSelectJour(e)}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          {props.t("Matière")} :
                        </Label>
                        <Select
                            options={matiere}
                            isSearchable={true}
                            onChange={e => getType(e)}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          {props.t("Type")} :
                        </Label>
                        <Select
                            options={type}
                            isSearchable={true}
                            onChange={e => setSelectType(e)}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          {props.t("Enseignant")} :
                        </Label>
                        <Select
                            options={enseignant}
                            isSearchable={true}
                            formatGroupLabel={formatGroupLabel}
                            onChange={e => setSelectEnseignant(e)}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          {props.t("Nombre de groupes")} :
                        </Label>
                        <Input
                            lg="3"
                            className="form-control"
                            placeholder="Entrer nombre de groupes"
                            type="number"
                            onChange={e => setNbrGroupe(e.target.value)}
                        />
                      </div>
                    </Col>
                  </Row>
                  <div style={{display: "flex", justifyContent: "center"}}>
                    {" "}
                    <Col lg="6">
                      <div className="text-center mt-4">
                        <button
                            type="button"
                            className="btn btn-primary "
                            onClick={save}
                        >
                          {props.t("Confirmer")}
                        </button>
                      </div>
                    </Col>
                  </div>
                </Form>
              </CardBody>
            </Card>
            <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
            />
          </Container>
        </div>
      </React.Fragment>
  )
}

export default withTranslation()(AddFicheLibre)
AddFicheLibre.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
