import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router"
import API from "../../apis"
import {Button, Col, Modal, ModalBody, ModalHeader, Spinner, Table} from "reactstrap"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Flip } from "react-toastify"

const AddNote = props => {
  if (localStorage.getItem("userAuthEnseignant")) {
    var userAuthEnseignant = JSON.parse(
      localStorage.getItem("userAuthEnseignant")
    )
    var enseignant_id = userAuthEnseignant.user.enseignant_id
  }
  const [etudiantList, setEtudiantList] = useState([])
  const [loading, setLoading] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)
  const [modalAfficher, setModalAfficher] = useState(false);
  const toggleAfficher = id => {
    setModalAfficher(!modalAfficher)
  }
  const Afficher = async () => {
    // setModalDelete(!modalDelete)
    setLoading(false)
    const res = await API.post("examen/passer/note/affichier/libre", {
      group_id: props.listGroup.id,
      matiere_id: props.matiere_id,
      type_examen_id: props.typeExamen,
      enseignant_id: enseignant_id,
      semestre_id: props.semestre,
      etudiants: etudiantList,
      date: props.date,
      type_matiere: props.type,
      affiche:1
    }).then(res => {
      props.history.push("/Note")
    })
  }
  useEffect(async () => {
    setModalAfficher(false)
    setModalDelete(false)
    const resE = await API.post("examen/passer/etudiants", {
      group_id: props.listGroup.id,
      matiere_id: props.matiere_id,
      type_examen_id: props.typeExamen,
      semestre_id: props.semestre,
      date: props.date,
    }).then(resE => {
      setEtudiantList(resE.data.Note)
      setLoading(true)
    })
  }, [props.listGroup.id])

  const changeNote = (event, index) => {
    if (/^[0-9,.]+$/.test(event)) {
      var note = event
    } else if (/,./.test(event)) {
      var note = event
    } else {
      toast.error("⛔ Note doit comporte seulement des chiffres !", {
        containerId: "A",
      })
    }
    if (parseFloat(note) >= 0 && parseFloat(note) <= 20) {
      setEtudiantList(
        etudiantList.map((el, id) =>
          id === index ? Object.assign(el, { note: note }) : el
        )
      )
    } else if (note == "") {
      setEtudiantList(
        etudiantList.map((el, id) =>
          id === index ? Object.assign(el, { note: "" }) : el
        )
      )
    } else {
      toast.error("⛔ Veuillez verifier la note saisie !!", {
        containerId: "A",
      })
    }
  }

  const changeAbsence = (event, index) => {
    let absence = event.target.checked
    if (absence == 1) {
      setEtudiantList(
        etudiantList.map((el, id) =>
          id === index ? Object.assign(el, { isPresent: 1, note: "" }) : el
        )
      )
    } else {
      setEtudiantList(
        etudiantList.map((el, id) =>
          id === index ? Object.assign(el, { isPresent: 0, note: 0 }) : el
        )
      )
    }
  }

  const save = async () => {
    setLoading(false)
    const res = await API.post("examen/passer/note/add/libre", {
      group_id: props.listGroup.id,
      matiere_id: props.matiere_id,
      type_examen_id: props.typeExamen,
      enseignant_id: enseignant_id,
      semestre_id: props.semestre,
      etudiants: etudiantList,
      date: props.date,
      type_matiere: props.type,
    }).then(res => {
      props.setSection(1)
    })
  }
  const validate = async () => {
    // setModalDelete(!modalDelete)
    setLoading(false)
    const res = await API.post("examen/passer/note/valider/libre", {
      group_id: props.listGroup.id,
      matiere_id: props.matiere_id,
      type_examen_id: props.typeExamen,
      enseignant_id: enseignant_id,
      semestre_id: props.semestre,
      etudiants: etudiantList,
      date: props.date,
      type_matiere: props.type,
    }).then(res => {
      props.setSection(1)
    })
  }
  const toggleDelete = id => {
    setModalDelete(!modalDelete)
  }
  const upload = async () => {}

  return (
    <React.Fragment>
      {loading ? (
        <div>
          <Col>
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="btn  mb-2 me-2"
                onClick={upload}
              >
                <i className="fas fa-file-import me-1" />
                Export
              </Button>
            </div>
          </Col>
          <div className="mt-2">
            <h5 style={{ color: "red" }}>
              {" "}
              NB : Cette action est irréversible
            </h5>
            <p>
              {
                "Faites attention ! en cas d'erreur vous n'avez pas la permission de mettre à jour. vous serez obligé de contacter le service administratif"
              }
            </p>
          </div>
          <Table className="mt-3" striped bordered hover>
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>CIN</th>
                <th style={{ textAlign: "center" }}>Etudiant</th>
                <th style={{ textAlign: "center" }}>Présence</th>
                <th style={{ textAlign: "center" }}>Note</th>
              </tr>
            </thead>
            <tbody>
              {etudiantList.map((el, index) => (
                <tr key={index}>
                  <td style={{ textAlign: "center" }}> {el.cin} </td>
                  <td style={{ textAlign: "center" }}> {el.nom} </td>
                  <td className="square-switch" style={{ textAlign: "center" }}>
                    <input
                      type="checkbox"
                      id={"square-switch" + index}
                      switch="bool"
                      checked={el.isPresent}
                      disabled={el.valider == 1 ? true : false}
                      onChange={e => changeAbsence(e, index)}
                    />
                    <label
                      htmlFor={"square-switch" + index}
                      data-on-label="P"
                      data-off-label="A"
                    />
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <input
                      type="text"
                      // onChange={e => changeNote(e.target.value, index)}
                      value={el.note != null ? el.note : ""}
                      disabled={el.valider == 1 ? true : false}
                      style={{ textAlign: "center" }}
                      onChange={e => {
                        let inputValue = e.target.value.replace(/[^0-9.,]/g, '');
                        inputValue = inputValue.replace(',', '.');
                        const parts = inputValue.split(/[.,]/);
                        if (parts.length > 1) {
                          const decimals = parts[1].slice(0, 2);
                          inputValue = `${parts[0]}.${decimals}`;
                        }
                        e.target.value = inputValue;
                        changeNote(e.target.value, index);
                      }}
                    ></input>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="mt-2">
            <h5 style={{ color: "red" }}>
              {" "}
              NB : Cette action est irréversible
            </h5>
            <p>
              {
                "Faites attention ! en cas d'erreur vous n'avez pas la permission de mettre à jour. vous serez obligé de contacter le service administratif"
              }
            </p>
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            {" "}
            <Col lg="6">
              <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  className="text-center mt-4"
              >
                <Button
                    type="button"
                    color="warning"
                    className="btn btn-warning  mb-2 me-2"
                    onClick={() => props.setSection(1)}
                >
                  Annuler
                </Button>
              </div>
            </Col>
            <Col lg="6">
              <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  className="text-center mt-4"
              >
                <button
                    type="button"
                    className="btn btn-primary "
                    onClick={save}
                >
                  Sauvgarder
                </button>
              </div>
            </Col>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Col lg="6">
              <div className="text-center mt-4">
                <button
                    type="button"
                    className="btn btn-success "
                    onClick={toggleDelete}
                >
                  Valider
                </button>
              </div>
            </Col>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Col lg="6">
              <div className="text-center mt-4">
                <button
                    type="button"
                    className="btn btn-secondary"
                    // onClick={toggleAfficher}
                >
                  Afficher la note pour les étudiants sur leur espace
                </button>
              </div>
            </Col>
          </div>
          <Modal isOpen={modalDelete} toggle={toggleDelete} centered={true}>
            <ModalHeader
                style={{ width: "100% !important", textAlign: "center !important" }}
                toggle={toggleDelete}
                tag="h4"
            >
             Validation de notes DS
            </ModalHeader>
            <ModalBody>
              <div
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    FontSize: "14px",
                    FontWeight: "700",
                    LineHeight: "18.375px",
                  }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <p>{ " Êtes-vous sûr de vouloir valider cette action ? Si vous la validez, vous ne pourrez plus modifier les notes !"}</p>
                </div>
                <div
                    className="hvr-push mt-4"
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginBottom: "15px",
                    }}
                >
                  <div>
                    <Button
                        color="info"
                        className="btn-rounded "
                        onClick={validate}
                    >
                      <i
                          style={{ color: "white" }}
                          className="far fa-thumbs-up"
                      ></i>
                      Oui
                    </Button>
                  </div>
                  <div>
                    <Button
                        onClick={toggleDelete}
                        color="danger"
                        className="btn-rounded "
                    >
                      <i
                          style={{ color: "white" }}
                          className="far fa-thumbs-down"
                      ></i>
                     Non
                    </Button>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
          <Modal isOpen={modalAfficher} toggle={toggleAfficher} centered={true}>
            <ModalHeader
                style={{ width: "100% !important", textAlign: "center !important" }}
                toggle={toggleAfficher}
                tag="h4"
            >
              Affichage des notes DS
            </ModalHeader>
            <ModalBody>
              <div
                  style={{
                    fontFamily: "Montserrat, sans-serif",
                    FontSize: "14px",
                    FontWeight: "700",
                    LineHeight: "18.375px",
                  }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <p>{ "Êtes-vous sûr de vouloir valider cette action ? Si vous la validez, les notes seront affichées automatiquement dans l'espace étudiant !"}</p>
                </div>
                <div
                    className="hvr-push mt-4"
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      marginBottom: "15px",
                    }}
                >
                  <div>
                    <Button
                        color="info"
                        className="btn-rounded "
                        onClick={Afficher}
                    >
                      <i
                          style={{ color: "white" }}
                          className="far fa-thumbs-up"
                      ></i>
                      Oui
                    </Button>
                  </div>
                  <div>
                    <Button
                        onClick={toggleAfficher}
                        color="danger"
                        className="btn-rounded "
                    >
                      <i
                          style={{ color: "white" }}
                          className="far fa-thumbs-down"
                      ></i>
                      Non
                    </Button>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>

      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <Spinner type="grow" className="ms-6" color="primary" />
          </div>
          <h4 style={{ textAlign: "center", marginTop: "2%" }} className="ms-6">
            Chargement en cours...
          </h4>
        </div>
      )}
      <ToastContainer
        transition={Flip}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withRouter(AddNote)
AddNote.propTypes = {
  listGroup: PropTypes.object,
  matiere_id: PropTypes.object,
  type: PropTypes.object,
  typeExamen: PropTypes.object,
  date: PropTypes.object,
  semestre: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
  history: PropTypes.object,
  element: PropTypes.object,
}
